import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    getIssueDetails: {},
    getPreviousRoute: "",
    UserEmail: null,
    ToggleBoolean: null,
    boolean: false,
    GetCurrentUser: {},
    TableWidth: [],
  },
  getters: {
    get_useremail: (state) => {
      return state.UserEmail;
    },
    get_toggle_boolean: (state) => {
      return state.ToggleBoolean;
    },
    get_correct_boolean: (state) => {
      return state.boolean;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_issue_details: (state) => {
      return state.getIssueDetails;
    },
    get_previous_route: (state) => {
      return state.getPreviousRoute;
    },
    get_table_width: (state) => {
      return state.TableWidth;
    },
  },
  mutations: {
    SET_USEREMAIL(state, EmailID) {
      state.UserEmail = EmailID;
    },
    SET_TOGGLE_BOOLEAN(state, ToggleBoolean) {
      state.ToggleBoolean = ToggleBoolean;
    },
    SET_CORRECT_LOGIN(state, boolean) {
      state.boolean = boolean;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    SET_ISSUE_DETAILS(state, getIssueDetails) {
      state.getIssueDetails = getIssueDetails;
    },
    SET_PREVIOUS_ROUTE(state, route) {
      state.getPreviousRoute = route;
    },
    SET_TABLE_WIDTH(state, TableWidth) {
      state.TableWidth = TableWidth;
    },
  },
  actions: {},
  modules: {},
});
