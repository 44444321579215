import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/HomePage",
    name: "HomePage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "HomePage" */ "../views/HomePage.vue"),
    children: [
      // Dashboard
      {
        name: "Customers",
        path: "/Customers",
        component: () =>
          import(
            /* webpackChunkName: "Customers" */ "../views/ChildHomePages/Customers.vue"
          ),
      },
      {
        name: "AuditLibrary",
        path: "/AuditLibrary",
        component: () =>
          import(
            /* webpackChunkName: "AuditLibrary" */ "../views/ChildHomePages/AuditLibrary.vue"
          ),
      },
      {
        name: "AuditSteps",
        path: "/AuditSteps",
        component: () =>
          import(
            /* webpackChunkName: "AuditSteps" */ "../views/ChildHomePages/AuditSteps.vue"
          ),
      },
      {
        name: "UserManagement",
        path: "/UserManagement",
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "../views/ChildHomePages/UserManagement.vue"
          ),
      },
      {
        name: "ChecklistLibrary",
        path: "/ChecklistLibrary",
        component: () =>
          import(
            /* webpackChunkName: "ChecklistLibrary" */ "../views/ChildHomePages/ChecklistLibrary.vue"
          ),
      },
      {
        name: "FieldRepository",
        path: "/FieldRepository",
        component: () =>
          import(
            /* webpackChunkName: "FieldRepository" */ "../views/ChildHomePages/FieldRepository.vue"
          ),
      },
      {
        name: "FormLibraries",
        path: "/FormLibraries",
        component: () =>
          import(
            /* webpackChunkName: "FormLibraries" */ "../views/ChildHomePages/FormLibraries.vue"
          ),
      },
      {
        name: "AuditLogs",
        path: "/AuditLogs",
        component: () =>
          import(
            /* webpackChunkName: "AuditLogs" */ "../views/ChildHomePages/AuditLogs.vue"
          ),
      },
      {
        name: "Settings",
        path: "/Settings",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../views/ChildHomePages/Settings.vue"
          ),
      },
      {
        name: "Themes",
        path: "/Themes",
        component: () =>
          import(
            /* webpackChunkName: "Themes" */ "../views/ChildHomePages/Themes.vue"
          ),
      },
      {
        name: "Invoices",
        path: "/Invoices",
        component: () =>
          import(
            /* webpackChunkName: "Themes" */ "../views/ChildHomePages/CreditRequests.vue"
          ),
      },
    ],
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: () => import("@/views/ChildHomePages/ReportIssue.vue"),
  },
];
const router = new VueRouter({
  base: `${process.env.BASE_URL}`,
  routes,
});
export default router;
